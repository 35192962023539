<template>
  <div class="package-list">
    <div class="package-list__header">Overblik over pakker</div>
    <div class="package-list__pod" v-if="podEnabled">
      <!-- <btn label="Send POD" @click="$emit('pod-click')" /> -->
    </div>

    <div class="package-list__items">
      <package-item
        v-for="(item, index) in packages"
        :key="index"
        :item="item"
      />
    </div>
  </div>
</template>

<script>
  import PackageItem from "@/components/PackageItem";
  //import Btn from "@/components/Btn";
  export default {
    name: "PackageList",
    components: { 
      PackageItem, 
      //Btn 
    },
    props: {
      packages: {
        type: Array,
        default: function () {
          return [];
        },
      },
      podEnabled: {
        type: Boolean,
        default: false,
      },
    },
  };
</script>

<style>
.package-list {
  position: relative;
}
.package-list__header {
  text-transform: uppercase;
  color: #56bae0;
  font-weight: bold;
  margin-bottom: 20px;
}

@media screen and (min-width: 768px) {
  .package-list__pod {
    position: absolute;
    right: 0;
    top: -10px;
  }
}
</style>
