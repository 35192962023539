<template>
  <div class="photos">
    <div >
      <img
        class="photo"
        :src="photo"
        v-for="(photo, index) in photos" :key="`photo-${index}`"
        @click="imageZoom(photo)"
      />
      <!-- The Modal -->
      <div id="myModal" class="modal" v-if="long_img" @click="closeZoom">
        <!-- <button value="close" @click="closeZoom" >Close It</button> -->
        <img class="modal-content" id="img01" :src="model_img">
        <div id="caption"></div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "Photos",
    props: {
      photos: {
        type: Array,
        default: function () {
          return [];
        },
      },
    },
    data() {
      return { 
        long_img: false,
        model_img : ""
      };
    },
    methods:{
      imageZoom(photo){
        this.long_img = true;
        this.model_img = photo;
      },
      closeZoom(){
        this.long_img = false;
        this.model_img = '';
      }
    }
  };
</script>

<style>
  .photos {
    margin-top: 40px;
  }
  .photo {
    border-radius: 8px;
    margin-bottom: 10px;
    width: 100%;
  }
  .modal {
      position: fixed; /* Stay in place */
      z-index: 99; /* Sit on top */
      padding-top: 100px; /* Location of the box */
      left: 0;
      top: 0;
      width: 100%; /* Full width */
      height: 100%; /* Full height */
      overflow: auto; /* Enable scroll if needed */
      background-color: rgb(0,0,0); /* Fallback color */
      background-color: rgba(0,0,0,0.9); /* Black w/ opacity */
  }
  /* Modal Content (image) */
  .modal-content {
      margin: auto;
      display: block;
      width: 35%;
      /* max-width: 75%; */
  }
  /* Caption of Modal Image */
  #caption {
      margin: auto;
      display: block;
      width: 80%;
      max-width: 700px;
      text-align: center;
      color: #ccc;
      padding: 10px 0;
      height: 150px;
  }
  @-webkit-keyframes zoom {
    from {-webkit-transform:scale(1)}
    to {-webkit-transform:scale(2)}
  }
  @keyframes zoom {
    from {transform:scale(0.4)}
    to {transform:scale(1)}
  }
  @-webkit-keyframes zoom-out {
    from {transform:scale(1)}
    to {transform:scale(0)}
  }
  @keyframes zoom-out {
      from {transform:scale(1)}
      to {transform:scale(0)}
  }
  /* Add Animation */
  .modal-content, #caption {
      -webkit-animation-name: zoom;
      -webkit-animation-duration: 0.6s;
      animation-name: zoom;
      animation-duration: 0.6s;
  }
  .out {
    animation-name: zoom-out;
    animation-duration: 0.6s;
  }
  @media only screen and (max-width: 700px){
    .modal-content {
      width: 100%;
    }
  }
  @media screen and (min-width: 1024px) {
    .photos {
      display: flex;
      flex-wrap: wrap;
    }
    .photo {
      width: calc(50% - 10px);
      margin-right: 10px;
    }
  }
</style>
