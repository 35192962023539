<template>
  <div class="statusbar">
    <div class="statusbar-bar">
      <div class="statusbar-bar-progress" :style="{ width: `${percent}%` }" />
    </div>
    <div class="statusbar-items">
      <statusbar-item
        v-for="(item, index) in formattedItems"
        :key="item.id"
        :item="item"
        :completed="current > index"
      />
    </div>
  </div>
</template>

<script>
import StatusbarItem from "@/components/StatusbarItem";

export default {
  name: "Statusbar",
  components: { StatusbarItem },
  props: {
    items: {
      type: Array,
      default: function () {
        return [];
      },
    },
    current: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    percent() {
      return (this.current / this.items.length) * 100;
    },
    formattedItems() {
      const itemWidth = 100 / this.items.length + "%";
      return this.items.map((item, index) => {
        return Object.assign({}, item, {
          width: itemWidth,
          completed: this.current > index,
        });
      });
    },
  },
};
</script>

<style>
.statusbar {
  position: relative;
  height: 100px;
  max-width: 680px;
  margin: 0 auto;
}
.statusbar-bar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 58px;
  border-radius: 58px;
  border: 1px solid #56bae0;
  overflow: hidden;
}
.statusbar-bar-progress {
  position: absolute;
  top: 0;
  left: 0;
  height: 58px;
  background-color: #56bae0;
}
.statusbar-items {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 120px;
  display: flex;
  padding: 0 10px;
}

@media screen and (min-width: 680px) {
  .statusbar {
    height: 120px;
  }
  .statusbar-bar {
    height: 78px;
  }
  .statusbar-bar-progress {
    height: 78px;
  }
}
</style>
