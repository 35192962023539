<template>
  <div class="column">
    <slot />
  </div>
</template>

<script>
export default {
  name: "Column",
};
</script>

<style>
@media screen and (min-width: 768px) {
  .column {
    width: 50%;
  }
  .column:first-child {
    margin-right: 25px;
  }
  .column:last-child {
    margin-left: 25px;
  }
}
</style>
