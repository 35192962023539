<script>
import Btn from "@/components/Btn";
    export default {
        name: 'Modal',
        props:['deliveryText'],
        components:{
            Btn
        },
        data(){
            return{
            }
        },
        created(){
            
        },
        methods: {
            close() {
                this.$emit('closeadd');
            },
            acceptClick(){
                this.$emit('acceptClick');    
            },
            notAcceptClick(){
                location.reload();
            }
        },
    };
</script>

<template>
  <transition name="modal-fade">
    <div class="modal-backdrop">
      <div class="modaladd"
        role="dialog"
        aria-labelledby="modalTitle"
        aria-describedby="modalDescription"
      >
        <header
          class="modal-header new-add-header"
          id="modalTitle"
        >
            <div class="modelheader">
                <!-- <slot name="header">
                    <span>4 timers interval</span>
                </slot> -->

                <button
                    type="button"
                    class="btn-close-1"
                    @click="close"
                    aria-label="Close modal"
                >
                    x
                </button>
            </div>
        </header>

        <section
          class="modal-body"
          id="modalDescription"
        >
          <slot name="body">
              <div class="form-field form-field__time">
                <div class="form-field-input-wrapper">
                    <span class="addtext">Godkend at din Levering - </span>
                    <span class="addtext">{{ deliveryText }}</span>
                </div>
              </div>
          </slot>
        </section>

        <footer class="modal-footer-add">
            <btn label="Godkend" class="btn-width-cls" @click="acceptClick"/>
            <btn label="annuller" class="btn-width-cls" @click="notAcceptClick"/>
        </footer>
      </div>
    </div>
  </transition>
</template>

<style>
    .modal-backdrop {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.3);
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .new-add-header{
        padding:  19px !important;
    }
    .model-custom-select {
 
        border: 1px solid #d0cccc;
        background-color: #fff;
        color: #56bae0;
        width: 200px;
        padding: 10px 20px;
        border-radius: 6px;
        font-size: 16px;
        box-shadow: rgba(0, 0, 0, 0.10) 0px 5px 15px;
    }
    .btn--right{
        position: relative;
        z-index:0
    }
    .modaladd {
        background: #FFFFFF;
        box-shadow: 2px 2px 20px 1px;
        overflow-x: auto;
        display: flex;
        flex-direction: column;
        width: 30%;
        box-shadow: none;
        border-radius: 10px;
        padding: 0 20px;
        height: 200px;
    }

    .modal-header,
    .modal-footer {
        padding: 15px;
        display: flex;
    }

    .modal-header {
        position: relative;
        border-bottom: 1px solid #eeeeee;
        color: #56bae0;
        justify-content: space-between;
        font-size: 18px;
        font-weight: 600;
    }

    .modal-footer-add {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
    }

    .modal-body {
        position: relative;
        padding: 20px 10px;
    }

    .btn-close-1 {
        position: absolute;
        top: 0;
        right: 0;
        border: none;
        font-size: 20px;
        padding: 10px;
        cursor: pointer;
        font-weight: bold;
        color: #56bae0;
        background: transparent;
    }

    .btn--green {
        color: white;
        font-size: 16px;
        background: #f15726;
        border-radius: 8px;
        padding: 13px 20px;
        display: flex;
        margin: 0 auto;
        border: none;
    }

    .modal-fade-enter,
    .modal-fade-leave-to {
        opacity: 0;
    }

    .modal-fade-enter-active,
    .modal-fade-leave-active {
        transition: opacity .5s ease;
    }

    .create-container {
        padding: 30px 0;
        max-width: 812px;
        margin: 0 auto;
    }
    .form-field__time{
        width: 100%;
        /* margin-left: 20px; */
    }
    .for-next{
        margin-bottom: 12px; 
    }
    .addtext{
        color: #000;
    }

    .btn-width-cls{
        width:150px;
    }

    @media (max-width:820px){
         .modaladd {
            width: 50%;
        }
        
    }
    @media (max-width:520px){
        .modaladd {
            width: 80%;
        }
        .btn-width-cls{
            width:110px;
        }
    }
</style>
