<template>
  <div class="columns">
    <slot />
  </div>
</template>

<script>
export default {
  name: "Columns",
};
</script>

<style>
@media screen and (min-width: 768px) {
  .columns {
    display: flex;
  }
}
</style>
