<template>
  <div class="status-message">
    <div class="status-message__header">
      <!-- {{ header }}
      <img
        class="status-message__exclamation"
        src="images/status-message-exclamation.svg"
      /> -->
    </div>
    <div class="status-message__message">{{ message }}</div>
  </div>
</template>

<script>
export default {
  name: "StatusMessage",
  props: {
    header: {
      type: String,
      default: "",
    },
    message: {
      type: String,
      default: "",
    },
  },
};
</script>

<style>
.status-message__header {
  font-size: 16px;
  font-weight: bold;
  color: #f15726;
  margin-bottom: 6px;
}
.status-message__exclamation {
  margin-bottom: 6px;
}
.status-message {
  margin: 0 auto;
  padding: 10px 0;
  text-align: center;
  max-width: 508px;
}
</style>
