<template>
  <div class="package-status-table">
    <div
      v-for="(row, index) in rows"
      :key="`row-${index}`"
      :class="{
        'package-status-table-row': true,
        'package-status-table-row--active': index === 0,
      }"
    >
      <div class="package-status-table-column1">
        <img class="package-status-table__icon" :src="statusIcon(index)" />
      </div>
      <div class="package-status-table-column2">
        {{ row.description }}
      </div>
      <div class="package-status-table-column3">
        <div class="package-status-table-row__date">{{ row.date }}</div>
        <div class="package-status-table-row__location">{{ row.location }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PackageStatusTable",
  props: {
    rows: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  methods: {
    statusIcon(index) {
      return index === 0
        ? "images/package-status-table-icon--active.svg"
        : "images/package-status-table-icon.svg";
    },
  },
};
</script>

<style>
.package-status-table {
  margin: 20px 5px 0 5px;
  margin-left: 5px;
}
.package-status-table-row {
  display: flex;
  border-top: 1px solid #fff;
  padding: 16px 0;
}
.package-status-table-row.package-status-table-row--active {
  color: #56bae0;
}
.package-status-table-column1 {
  width: 40px;
  padding: 0 12px;
  display: flex;
  justify-content: center;
}
.package-status-table-column2 {
  font-size: 14px;
  padding: 0 10px;
  width: 100%;
}
.package-status-table-column3 {
  font-size: 10px;
  width: 150px;
  text-align: right;
}
.package-status-table__icon {
  width: 30px;
  height: auto;
}

@media screen and (min-width: 1024px) {
  .package-status-table-column2 {
    font-size: 16px;
    padding-top: 16px;
    padding-bottom: 16px;
  }
  .package-status-table-column3 {
    font-size: 12px;
    width: 200px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
@media screen and (min-width: 1280px) {
  .package-status-table-column2 {
    font-size: 16px;
  }
  .package-status-table-column3 {
    font-size: 16px;
    width: 200px;
  }
}
</style>
