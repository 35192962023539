<template>
  <div class="package">
    <div
      :class="{ 'package-bar': true, 'package-bar--expanded': expanded }"
      @click="expanded = !expanded"
    >
      <div class="package-bar-column1">
        <img class="package-bar__icon" :src="barIcon" />
      </div>
      <div class="package-bar-column2">
        <div class="package-bar__header">
          {{ item.status }}
        </div>
        <div class="package-bar__header">
          {{ item.content }}
        </div>
        <div class="package-bar__id">
          Pakkenr: <strong>{{ item.packageNumber }}</strong>
        </div>
      </div>
      <img class="package-bar__chevron" src="images/package-bar-chevron.svg" />
    </div>
    <div v-show="expanded" class="package__content">
       <columns>
        <column>
          <columns>
              <package-info-card style="width:100%" :item="item" />
          </columns>
          <columns>
              <package-status-table style="width:100%" :rows="item.statuses" />
          </columns>
        </column>
        <column>
          <columns>
              <location-map v-if="item.location.length != 0" :coordinates="item.location"/>
          </columns>
          <columns>
              <photos v-if="item.photos" :photos="item.photos" />
          </columns>
        </column>
      </columns>
      <!-- <columns>
        <column><package-status-table :rows="item.statuses" /></column>
        <column><package-info-card :item="item" /></column>
      </columns>
      <columns>
        <column><photos v-if="item.photos" :photos="item.photos" /></column>
        <column
          ><location-map v-if="item.location" :coordinates="item.location"
        /></column>
      </columns> -->
    </div>
  </div>
</template>

<script>
import Columns from "@/components/Columns";
import Column from "@/components/Column";
import PackageStatusTable from "@/components/PackageStatusTable";
import PackageInfoCard from "./PackageInfoCard.vue";
import Photos from "./Photos.vue";
import LocationMap from "./LocationMap.vue";
export default {
  name: "Package",
  components: {
    Columns,
    Column,
    PackageStatusTable,
    PackageInfoCard,
    Photos,
    LocationMap,
  },
  data() {
    return { expanded: false };
  },
  props: {
    item: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  computed: {
    barIcon() {
      return this.expanded
        ? "images/package-bar-icon--expanded.svg"
        : "images/package-bar-icon.svg";
    },
  },
};
</script>

<style>
.package {
  margin-top: 20px;
}
.package-bar {
  position: relative;
  background-color: #fff;
  color: #004a64;
  border-radius: 8px;
  padding: 12px 40px 12px 12px;
  display: flex;
  cursor: pointer;
  transition: background-color 0.1s ease-out, color 0.1s ease-out;
}
.package-bar.package-bar--expanded {
  background-color: #56bae0;
  color: #fff;
}
.package__content {
  margin-bottom: 60px;
}
.package-bar-column1 {
  width: 40px;
  padding-right: 14px;
  display: flex;
  justify-content: center;
}
.package-bar-column2 {
  flex-grow: 1;
}
.package-bar__icon {
  width: 30px;
  height: auto;
}
.package-bar__id {
  margin-top: 10px;
  text-transform: uppercase;
}
.package-bar__chevron {
  position: absolute;
  right: 6px;
  top: 50%;
  transform: translate(-50%, -50%) rotate(180deg);
}
.package-bar--expanded .package-bar__chevron {
  transform: translate(-50%, -50%);
}
@media screen and (min-width: 1024px) {
  .package-bar-column2 {
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
  }
  .package-bar__id {
    margin-top: 0;
  }
}
@media (hover: hover) {
  .package-bar:hover {
    background-color: #d0e9f3;
  }
  .package-bar.package-bar--expanded:hover {
    background-color: #56bae0;
  }
}
</style>
