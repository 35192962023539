<template>
  <div class="delivery-message">
    <div class="delivery-message__header">{{ header }}</div>
    <div class="delivery-message__time">{{ time }}</div>
    <div class="delivery-message__date">{{ date }}</div>
    <!-- <div class="delivery-message__date">{{ date_new | formatDate}}</div> -->
  </div>
</template>

<script>
export default {
  name: "DeliveryMessage",
  props: {
    header: {
      type: String,
      default: "",
    },
    time: {
      type: String,
      default: "",
    },
    date: {
      type: String,
      default: "",
    }
  },
};
</script>

<style>
.delivery-message {
  padding: 10px 0;
}
.delivery-message__header {
  font-size: 16px;
  font-weight: 300;
}
.delivery-message__time {
  font-size: 36px;
  font-weight: bold;
  color: #05bce4;
}
.delivery-message__date {
  font-size: 20px;
  font-weight: bold;
}
</style>
