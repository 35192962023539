<template>
  <div class="location-map">
      <gmap-map
        :zoom="14"
        v-for="(coordinate, index) in coordinates"
        :key="`coordinate-${index}`"   
        :center="{lat : coordinate.lat , lng : coordinate.lng }"
        style="width:650px; height: 300px; margin-bottom:20px;"
      >
      <gmap-marker
        :key="index"
        v-for="(m, index) in markers"
        :position="m"
        @click="center=m"
      ></gmap-marker>
      </gmap-map>
      <!-- <img class="location-map-dummy" src="images/location-map.png" /> --> 
  </div>
</template>

<script>
  export default {
    name: "LocationMap",
    props: {
      coordinates: {
        type: Array,
        default: function () {
          return { lat: 0, lng: 0 };
        },
      },
    },
    data() {
      return {
        center: { 
          lat: 0,
          lng: 0
        },
        markers: [],
        currentPlace: null
      };
    },
    mounted() {
    this.geolocate();
  },
 
  methods: {
    setPlace(place) {
      this.currentPlace = place;
    },
    geolocate: function() {
        this.markers = [
          {
              lat: this.coordinates[0].lat,
              lng: this.coordinates[0].lng,
          },
      ];
 
    }
  }
   
  };
</script>

<style>
.location-map {
  margin-top: 40px;
  border-radius: 8px;
  background-color: #fff;
  padding: 20px;
  padding-bottom: 0px;
}
.location-map-dummy {
  width: 100%;
}
</style>
