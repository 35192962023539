<template>
  <div class="package-info-card">
    <div class="package-info-card__id">{{ item.packageNumber }}</div>
    <div class="package-info-card-column1">
      <div class="package-info-card__ref-header">Pakkeoplysninger</div>
      <div class="package-info-card__ref-row">
        <div class="package-info-card__ref-row-title">Vægt (kg)</div>
        <div class="package-info-card__ref-row-value">{{ item.weight }}</div>
      </div>
      <div class="package-info-card__ref-row">
        <div class="package-info-card__ref-row-title">Volumen</div>
        <div class="package-info-card__ref-row-value">{{ item.volume }}</div>
      </div>
    </div>
    <!-- <div class="package-info-card-column2">
      <div class="package-info-card__contents-header">Pakkeindhold</div>
      <div class="package-info-card__contents-description">
        {{ item.description }}
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  name: "PackageInfoCard",
  props: {
    item: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
};
</script>

<style>
.package-info-card {
  margin-top: 20px;
  background-color: #fff;
  color: #1d4962;
  padding: 20px 16px;
  border-radius: 8px;
}
.package-info-card__id {
  color: #56bae0;
  font-weight: bold;
  /* font-size: 36px; */
  font-size: 22px;
  text-align: right;
}
.package-info-card__ref-header {
  font-weight: bold;
  margin-bottom: 10px;
}
.package-info-card__ref-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}
.package-info-card__contents-header {
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 10px;
}
</style>
